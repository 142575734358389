import React, { useState, useEffect } from "react"
import fontsList from "./fonts.json"

function FontTestingTool() {
  const [fontFamily, setFontFamily] = useState('Roboto')
  const [selectedFont, setSelectedFont] = useState('Roboto Regular')
  const [selectedFamilyFonts, setSelectedFamilyFonts] = useState([])
  const [text, setText] = useState('')
  const [size, setSize] = useState(60)

  const [isLoading, setIsLoading] = useState(true)
  const [dataURL, setDataURL] = useState()

  const [fetchTimer, setFetchTimer] = useState(null);

  function delayFetch() {
    if (fetchTimer) {
      clearTimeout(fetchTimer)
      setFetchTimer(null)
    }

    setFetchTimer(
      setTimeout(() => fetchImg(), 300)
    )
  }

  const fetchImg = async () => {
    setIsLoading(true)

    try {
      const { dataURL } = await fetch(`${process.env.GATSBY_API_URL}/font-testing-tool/png`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          font: selectedFont,
          size,
          text
        }),
      }).then(r => r.json())

      setDataURL(dataURL)
    } catch (err) {
      console.log(`Error loading img!`)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    // load roboto fonts
    const robotoFamily = fontsList.find(f => f.name === 'Roboto')
    setSelectedFamilyFonts(robotoFamily.opts.map(f => f.name))

    fetchImg()
  }, [])

  useEffect(() => {
    delayFetch()
  }, [selectedFont, text, size])


  // const handleFontFamily = e => {
  //   const family = e.target.value
  //   setFontFamily(family)

  //   const fonts = fontsList.find(f => f.name === family)
  //   const defaultFont = (fonts.opts.find(f => f.name.indexOf('Regular') > -1) || fonts.opts[0])

  //   setSelectedFont(defaultFont.name)
  //   setSelectedFamilyFonts(fonts.opts.map(f => f.name))
  // }
  const handleFontFamily = family => {
    setFontFamily(family)

    const fonts = fontsList.find(f => f.name === family)
    const defaultFont = (fonts.opts.find(f => f.name.indexOf('Regular') > -1) || fonts.opts[0])

    setSelectedFamilyFonts(fonts.opts.map(f => f.name))

    setSelectedFont(defaultFont.name)
  }

  const handleFont = e => {
    setSelectedFont(e.target.value)
  }

  const handleText = e => {
    setText(e.target.value)
  }

  const handleSize = e => {
    setSize(e.target.value)
  }

  return (
    <>
      <div style={{
        width: '740px',
        maxWidth: '100%',
        margin: 'auto',
        padding: '5px'
      }}>
        <form onSubmit={e => {
          e.preventDefault()
        }}>
          <div>
            {/*
          <label>Choose Font Family</label>
          <select defaultValue={fontFamily} onChange={handleFontFamily}>
            {fontsList.map(family => (
              <option value={family.name} key={family.name}>{family.name}</option>
            ))}
          </select>
          */}
            <div>
              {fontsList.map(({ name }) => (
                name === fontFamily
                  ? <span style={{ padding: '5px' }}>{name}</span>
                  : <a
                    href="#name"
                    onClick={e => {
                      e.preventDefault()
                      handleFontFamily(name)
                    }}
                    style={{ padding: '5px' }}
                  >{name}</a>
              ))}
            </div>
          </div>

          <div style={{
            display: 'flex',
            padding: '10px 0',
          }}>
            <div>
              <select value={selectedFont} onChange={handleFont}>
                {selectedFamilyFonts.map(name => (
                  <option value={name} key={name}>{name}</option>
                ))}
              </select>
            </div>

            <div style={{
              flex: 1,
              padding: '0 10px'
            }}>
              <input
                type="text"
                defaultValue={text}
                onChange={handleText}
                style={{
                  width: '100%'
                }}
              />
            </div>

            <div>
              <select value={size} onChange={handleSize}>
                {[12, 14, 18, 24, 36, 48, 60, 72, 144].map(s => (
                  <option value={s} key={s}>{s}</option>
                ))}
              </select>
            </div>
          </div>
        </form>

        <div>
          {!isLoading 
            ? <img src={dataURL} style={{
                width: '720px',
                maxWidth: '100%'
              }} />
            : <div style={{
                height: '112px',
                width: '100%'
              }}>
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
          }
        </div>
      </div>
      <style>
      {`
      .lds-spinner {
        color: official;
        position: relative;
        width: 80px;
        height: 80px;
        margin: 48px auto 0;
      }
      .lds-spinner div {
        transform-origin: 40px 40px;
        animation: lds-spinner 1.2s linear infinite;
      }
      .lds-spinner div:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 6px;
        height: 18px;
        border-radius: 20%;
        background: #333;
      }
      .lds-spinner div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
      }
      .lds-spinner div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
      }
      .lds-spinner div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
      }
      .lds-spinner div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
      }
      .lds-spinner div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
      }
      .lds-spinner div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
      }
      .lds-spinner div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
      }
      .lds-spinner div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
      }
      .lds-spinner div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
      }
      .lds-spinner div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
      }
      .lds-spinner div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
      }
      .lds-spinner div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
      }
      @keyframes lds-spinner {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      `}
      </style>
    </>
  )

}

export default FontTestingTool
