import React from "react"
import { Helmet } from "react-helmet"
import FontTestingTool from "../components/font-testing-tool"

const FontTestingToolPage = () => (
  <>
    <Helmet>
      <title>Font Testing Tool</title>
      <meta name="robots" content="noindex" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
    </Helmet>
    <FontTestingTool />
  </>
)

export default FontTestingToolPage
